import React, { Component } from "react";
import logo from "./medulogo.png";
import "./App.css";
import awsmobile from "./aws-exports";
import Pulldata from "./Components/pulldata.js";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Amplify from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { withAuthenticator } from "@aws-amplify/ui-react";

Amplify.configure(awsmobile);
console.log(Auth);
class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <div className="App">
            <header>
              <img src={logo} className="App-logo" alt="logo" />
            </header>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/digitaldesk">Digital Desk</Link>
            </li>
          </ul>

          <hr />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/digitaldesk" element={<Pulldata />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default withAuthenticator(App);

function Home() {
  return (
    <div>
      <h1>Home Page, Select the testers Organization</h1>
    </div>
  );
}
