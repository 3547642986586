import React, { Component } from "react";
//import connector from './onConnect.js';
import Sockette from "sockette";

class Pulldata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: [],
      stud: [],
      stat: "",
    };
    this.selectStudent = this.selectStudent.bind(this);
  }

  onOpen = (e) => {
    console.log("connected:", e);
  };

  onMessage = (e) => {
    console.log("Message Received:", e);
    let msg = e.data;
    let pmsg = JSON.parse(msg);
    if (pmsg.Items) {
      let items = pmsg.Items;

      let studentList =
        items.length > 0 &&
        items.map((item, i) => {
          return (
            <option
              data-exid={item.ExamID}
              data-ins={item.Instructor}
              data-iem={item.InstructorEmail}
              data-stem={item.StudentEmail}
              data-sid={item.StudentID}
              data-aid={item.TestingAids}
              data-tall={item.TimeAllowed}
              data-stud={item.Student}
              data-conn={item.connectId}
              data-accom={item.Accomodations}
              key={i}
              value={item.connectId}
            >
              {item.Student}
            </option>
          );
        });
      console.log(studentList);
      this.setState({ student: studentList });
      return items;
    }
  };

  selectStudent = (e) => {
    let idx = e.target.selectedIndex;
    let dataset = e.target.options[idx].dataset;
    let args = [
      dataset.conn,
      dataset.exid,
      dataset.ins,
      dataset.iem,
      dataset.stud,
      dataset.stem,
      dataset.sid,
      dataset.aid,
      dataset.tall,
      dataset.accom,
    ];
    this.setState({
      stud: args,
    });
  };

  onSend = () => {
    console.log("Sending Message");
    this.ws.json({
      action: "proctorconnect",
    });
  };

  onAllow = () => {
    console.log("Allowing Student");
    let ily = this.state.stud[0];
    this.ws.json({
      action: "onAllow",
      data: ily,
    });
    this.setState({ stat: "Allowed Student" });
  };

  onDeny = () => {
    console.log("Denying Student");
    let ily = this.state.stud[0];
    this.ws.json({
      action: "onDeny",
      data: ily,
    });
    this.setState({ stat: "Denied Student" });
  };

  componentDidMount() {
    this.ws = new Sockette(
      "wss://aa42g17ph3.execute-api.us-east-2.amazonaws.com/development",
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: this.onOpen,
        onmessage: this.onMessage,
        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => console.log("Stop Attempting!", e),
        onclose: (e) => console.log("Closed!", e),
        onerror: (e) => console.log("Error:", e),
      }
    );
  }

  render() {
    return (
      <div>
        <button onClick={this.onSend}>Get Students</button>
        <select onChange={this.selectStudent}>
          <option value="default"> Pick a Student</option>
          {this.state.student}
        </select>
        <h2>Student: {this.state.stud[4]}</h2>
        <h2>Student Email: {this.state.stud[5]}</h2>
        <h2>Student ID: {this.state.stud[6]}</h2>
        <h2>Instructor: {this.state.stud[2]}</h2>
        <h2>Instructor Email: {this.state.stud[3]}</h2>
        <h2>Exam ID: {this.state.stud[1]}</h2>
        <h2>Testing Aids: {this.state.stud[7]}</h2>
        <h2>Time Allowed: {this.state.stud[8]}</h2>
        <h2>Accommodation Instructions: {this.state.stud[9]}</h2>
        <button onClick={this.onAllow}>Allow</button>
        <button onClick={this.onDeny}>Deny</button>
        <div>
          <h3>Status: {this.state.stat}</h3>
        </div>
      </div>
    );
  }
}

export default Pulldata;
